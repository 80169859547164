import React, { Component } from 'react';
import { Box, Center }  from '@chakra-ui/react';
import {Logo} from '../Logo';

class OnboardLogo extends Component {

render(){
return (
    <Box display='inline-flex' align="center"
    py="3"
        mx="4"
    mb={10}
    >
        <Center>
            <Logo mb={5} w={200} mt={5} pointerEvents="none" />
        </Center>
    </Box>
)
}
}

export default OnboardLogo;