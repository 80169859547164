import React, {useState} from 'react';
import {
  Button, Modal, ModalOverlay,
  ModalContent, ModalHeader, ModalFooter, Box,
  ModalBody, ModalCloseButton, Text, Center, Icon
} from '@chakra-ui/react';
import { FaTrash } from 'react-icons/fa';

const DeleteModal = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
 
      <Button   display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      w="50%"               
      padding="12.6933px 30.8267px 12.6933px 31.7333px"
      gap="6px"      
      color="#FF0C3E"
        borderRadius="6px"
        fontSize={'10px'}
        border={'1px solid #D1D5DB'} onClick={() => setIsOpen(true)}>
        <Icon as={FaTrash} ></Icon>
        Delete this entry</Button>

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} >
        <ModalOverlay />
        <ModalContent 
        >
          <ModalBody p={10}
            bg={'#F7F7F7'}
            borderRadius="8px"
            >
            <Box bg={"#FFFFF"} boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 1px rgba(0, 0, 0, 0.08)"
            borderRadius="8px" p={4}>
                      <Text
                        top="calc(50% - 23.05px/2 - 0px)"
                        fontFamily='Public Sans'
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="19px"
                        lineHeight="23px"
                        display="flex"
                        alignItems="center"
                        color="#000000"
                        
                      >Please Confirm</Text>
                      <Center
                      fontFamily="Public Sans"
                      fontStyle="normal"
                      fontWeight="400"
                      fontSize="16px"
                      lineHeight="19px"
                      display="flex"
                      alignItems="center"
                      pt={10}    
              color="#FF0000">Are you sure you want to delete this entry?</Center>
              <ModalFooter textAlign={'center'} justifyContent='center'>
                      <Button onClick={() => setIsOpen(false)}
                      fontFamily="Work Sans"
                      fontStyle="normal"
                      fontWeight="600"
                      fontSize="14px"
                      lineHeight="23px"
                      display="flex"
                      alignItems="center"            
                      color="rgba(21, 25, 32, 0.5)"
                      >No</Button>
                      <Button onClick={props.onClick} ml={3}
                      fontFamily="Work Sans"
                      fontStyle="normal"
                      fontWeight="600"
                      fontSize="14px"
                      lineHeight="23px"
                      display="flex"
                      alignItems="center"   
                      bg="#233065"    
                      color="white"
                      >
              Continue
            </Button>
              </ModalFooter>
              </Box>
          </ModalBody>

        
        </ModalContent>
      </Modal>
    </>
  )
}
export default DeleteModal