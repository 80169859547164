import React from 'react';
import { 
  Box, 
  useColorModeValue, 
  Hide
} from '@chakra-ui/react';
import Sidebar from '../components/sidebarComponent';
import HeaderDashboard from '../components/headerDashboard';
import { useAuth } from '../auth/auth';

const Dashboard = () => {
  let auth = useAuth();
  console.log(auth)
//   const [users, setUsers] = useState([]);
//   useEffect(() => {
//       setUsers(users);
//     });
    

  return (
    <Box display={'flex'} bg={useColorModeValue('#F5F5F5')} minH="100vh" position={'relative'} width={'auto'}>
    <Hide below="md">
        <Sidebar></Sidebar>
    </Hide>

      <Box flexDirection={'column'} w={'100%'} minH="100vh">
         <HeaderDashboard></HeaderDashboard>
        {/* <HeaderDashboard name={auth.user.applicationUser}></HeaderDashboard> */}
        <Box m={10} p={5} borderRadius={'6px'}  bg={'white'} height={'100vh'}>

          jksdfjkdkj
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
