import React from 'react';
import { 
    Routes,
    Route } from 'react-router-dom';
import Login from '../pages/SignIn';
import Dashboard from '../pages/Dashboard';
import { AuthProvider } from '../auth/auth';
import { RequireAuth } from '../auth/requireauth';
import Submission from '../pages/Submission';
import Policies from '../pages/Policies';
import Resource from '../pages/Resource';
import Request from '../pages/Request';
import Teams from '../pages/Team';
import Media from '../pages/Media';
import CreateContact from '../pages/CreateContact';
import CreatePolicies from '../pages/CreatePolicies';
import CreateResource from '../pages/CreateResource';
import CreateRequest from '../pages/CreateRequest';
import CreateTeam from '../pages/CreateTeam';
import Profile from '../pages/Profile';

const AppRoute = () => (
    
        <AuthProvider>
        <Routes>
            <Route path="/" exact element={<Login />} />
            <Route path="/resource" element={<Resource />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} />
            <Route path="/submission" element={<RequireAuth><Submission /></RequireAuth>} />
            <Route path="/policies" element={<RequireAuth><Policies /></RequireAuth>} />
            <Route path="/requests" element={<RequireAuth><Request /></RequireAuth>} />
            <Route path="/team" element={<RequireAuth><Teams /></RequireAuth>} />
            <Route path="/media" element={<RequireAuth><Media /></RequireAuth>} />
            <Route path="/media/:id" element={<RequireAuth><Media /></RequireAuth>} />
            <Route path="/create_contact" element={<RequireAuth><CreateContact /></RequireAuth>} />
            <Route path="/create_policies" element={<RequireAuth><CreatePolicies /></RequireAuth>} />
            <Route path="/create_resource" element={<RequireAuth><CreateResource /></RequireAuth>} />
            <Route path="/create_request" element={<RequireAuth><CreateRequest /></RequireAuth>} />
            <Route path="/create_team" element={<RequireAuth><CreateTeam /></RequireAuth>} />
            <Route path="/profile" element={<RequireAuth><Profile /></RequireAuth>} />
            <Route path="/create_contact/:id" element={<RequireAuth><CreateContact /></RequireAuth>} />
            <Route path="/create_policies/:id" element={<RequireAuth><CreatePolicies /></RequireAuth>} />
            <Route path="/create_resource/:id" element={<RequireAuth><CreateResource /></RequireAuth>} />
            <Route path="/create_request/:id" element={<RequireAuth><CreateRequest /></RequireAuth>} />
            <Route path="/create_team/:id" element={<RequireAuth><CreateTeam /></RequireAuth>} />
            
            </Routes>
        </AuthProvider>
    
);

export default AppRoute;
