import React, { useState, useEffect } from 'react';

import { 
  Box, 
  useColorModeValue, 
 Hide
} from '@chakra-ui/react';
import Sidebar from '../components/sidebarComponent';
import HeaderDashboard from '../components/headerDashboard';
import DataTable from '../components/dataTable';
import addnew from '../files/add-circle.svg';
import { Admin } from '../services/admin';

const Request = () => {

  const [data, setRequest] = useState([]);
  useEffect(() => {
    const newData = new Admin();
    newData.fetchRequests().then((response) => {
      console.log(response)
      // if (response.statusCode == 200) {
        setRequest(response);
        console.log(data);
      // }
    })
  }, [data]);


const columns = [ {
  key: 'fullName',
  label: 'Full Name',
  sortable: true
  },
    {
      key: 'email',
      label: 'Email',
      sortable: true
  },
  {
    key: 'phoneNumber',
    label: 'Phone Number',
    sortable: true
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true
    },
  {
  key: 'actions',
  label: 'Actions'
    }];


  return (
    <Box display={'flex'} bg={useColorModeValue('#F5F5F5')} minH="100vh" position={'relative'} width={'auto'}>
    <Hide below="md">
        <Sidebar></Sidebar>
    </Hide>

      <Box flexDirection={'column'} w={'100%'} minH="100vh">
         <HeaderDashboard></HeaderDashboard>
        {/* <HeaderDashboard name={auth.user.applicationUser}></HeaderDashboard> */}
         <Box m={10} p={5} borderRadius={'6px'}  bg={'white'} minH={'100vh'}>

          
          <DataTable columns={columns} data={data} buttons={{ show: true, fields: ["Edit", "Delete"] }} add={{
              to: "/create_request",
              title: "Add New Subscriber",
            icon: addnew,
            delete: "request"
          }}
          sort={"createdAt"}
          ></DataTable>
          </Box>
        </Box>
      </Box>
    
  );
};

export default Request;
