import React from 'react';
import {
  Button, Text, Box, Input, Image
} from '@chakra-ui/react';
let document = window.location.origin+'/files/document.svg'

const SummaryDocument = (props) => {
  
  return (
                       
                <Box>
                          <Text
                          fontFamily="Manrope"
                          fontStyle="normal"
                          fontWeight="600"
                          fontSize="16px"
                          lineHeight="20px"
                          color="#233065"
                          mb={5}    
      >Summary of Policy Document</Text>
          { props.result && (props.result.summary ?
                      <a href={props.result.summary ? `${process.env.REACT_APP_API_URL}/upload/${props.result.summary}` : "#"} target="_blank" rel="noopener noreferrer">
          <Button>{props.result.summary ? "View Document" : ""} </Button></a> : "")}
         
              
        <Box
    mt={'5'}
    boxSizing="border-box"
    display="flex"
    flexDirection="column"
    alignItems="center"
    padding="22px 26px 26px"
    gap="14px"
    height="144px"
    border="2px dashed #D1D5DB"
              borderRadius="6px"
              cursor={'pointer'}
              
          >
                
                  <Image src={document} height="60px"></Image>
             
              <Text
                  fontFamily="Manrope"
                  fontStyle="normal"
                  fontWeight="600"
                  fontSize="16px"
                  lineHeight="20px"
                  color="#233065"
                  textAlign={'center'}
                  mb={5}
              >Click to upload a document
                </Text>
        <Input type={'file'} name="summary"
          width={'auto'}
                  height="164px" opacity={0}
                  position={'absolute'}
                  cursor={'pointer'}
                  marginTop={'-10px'}
    //   top={0} bottom={0}
      onChange={props.onChange} />
      </Box>

                          </Box>
                  
  
  )
}
export default SummaryDocument