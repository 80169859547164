import { Button, FormControl, Input, Flex, Image, Badge } from '@chakra-ui/react';
import React, { useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import DotMenu from './dotMenu';
import { Admin } from '../services/admin';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DataTable = (props) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState({ key: props.sort, direction: 'desc' });
  const [filter, setFilter] = useState('');
  const [hideButtons, setHideButtons] = useState(props.buttons.show);
  const navigate = useNavigate();
  
  // if (props.buttons.show) {
  //   console.log(props.buttons.show)
  //   setHideButtons(props.buttons.show)
  // }
  const handleSort = (key) => {
    if (sort.key === key) {
      setSort({ key, direction: sort.direction === 'desc' ? 'desc' : 'asc' });
    } else {
      setSort({ key, direction: 'desc' });
    }
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  const handleEdit = (event, row, page) => {
    navigate(`${page}/${row.id}`)
  };

  const handleDelete = (event, row, type) => {
    const deleteData = new Admin; 
    deleteData.deleteData(type, row.id)
    .then((response) => {
      response.statusCode !== 200 ? toast.error(response.message) : toast.success(response.message)   
    })
    .catch((error) => {
      console.error(error);
    });
  };

  const filteredData = props.data && (props.data.filter(
    (item) => Object.values(item)
      .map((value) => value.toString().toLowerCase())
      .join(' ')
      .includes(filter.toLowerCase())
  ));
 
  const sortedData = filteredData.sort(
    (a, b) => (sort.direction === 'asc' ? 1 : -1) * (a[sort.key] > b[sort.key] ? 1 : -1)
  );

  const pagedData = sortedData.slice((page - 1) * pageSize, page * pageSize);

  return (
    <div>
      <ToastContainer></ToastContainer>
      <div className='entries' style={{ fontSize: '13px' }}>
           <span marginRight={15}> Show </span>
                <select value={pageSize} onChange={handlePageSizeChange} className={'select'}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
                </select> entries</div>
     <Flex>
      <FormControl my='5'>
        <Input type="text" value={filter} placeholder='Search table' onChange={handleFilterChange}
          width='300px'
          borderRadius='10px' style={{ border: '1px solid #grey' }} />
        </FormControl>
        <Link to={props.add.to}
        fontFamily='Poppins'
        fontStyle="normal"
        fontWeight="600"
          fontSize="12px"
          lineHeight="21px"
        display="flex"
        alignItems="center"
        color="#233065"
        
        >
        <Button my='5' 
          px={5}
          fontSize="12px"
          background="#F1F7FF"
          borderRadius="5.24362px"
        > 
          <Image src={props.add.icon} mr={'10px'}></Image>
            {props.add.title}</Button>
            </Link>
        </Flex>
      <table>
  <thead>
    <tr>
      {props.columns.map((column) => (
        <th key={column.key} onClick={() => handleSort(column.key)}>
          
          {hideButtons && column.key === "actions" ? "" : column.label  }
          {sort.key === column.key ? (
            sort.direction === 'asc' ? ' ▲' : ' ▼'
          ) : ''}
        </th>
      ))}
    </tr>
  </thead>
        <tbody>
    { pagedData && (pagedData.length > 0 ? 
    pagedData.map((row) => (
      <tr key={row.id}>
        {props.columns.map((column) => (
          <td key={column.key}>
           {column.key === 'actions' ? (
              <DotMenu
                handleDelete={(event) => handleDelete(event, row, props.add.delete)}
                handleEdit={(event) => handleEdit(event, row, props.add.to)}
              ></DotMenu>
            ) : (
              <>
                {column.key === 'status' ? (
                  <>
                    {row[column.key] === 'Published' ? (
                      <Badge colorScheme='green'>{row[column.key]}</Badge>
                    ) : (
                      <Badge colorScheme='red'>{row[column.key]}</Badge>
                    )}
                  </>
                ) : column.key === 'updatedAt' || column.key === 'createdAt' ? (
                  <>{new Date(row[column.key]).toLocaleString('en-us', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                  })}</>
                ) : (
                  <>
                    {row[column.key]}
                  </>
                )}
              </>
            )}

              
        
          </td>
        ))}
      </tr>
    )): <tr display="flex" textAlign="center" justifyContent="center">No data found</tr>)}
  </tbody>
  <tfoot>
    <tr>
      <td colSpan={props.columns.length}>
              <div>
                  
                
                <span marginRight={15}> Showing {(page - 1) * pageSize + 1} to {(page * pageSize) > filteredData.length ? filteredData.length : page * pageSize } of  {filteredData.length} entries </span> <span>(filtered from {props.data.length} entries )</span>
                <Flex style={{ float: 'right'}}>
          <Button background="#F1F7FF" borderRadius={'8px'} disabled={page === 1} onClick={() => handlePageChange(null, page - 1)}>
            <FaAngleLeft></FaAngleLeft>
                  </Button>
                  
                  {/* <PageNavigation disabled={page === Math.ceil(filteredData.length / pageSize) } totalPages={pageSize} currentPage={page} ></PageNavigation> */}
          <Button background="#F1F7FF" borderRadius={'8px'} disabled={page === Math.ceil(filteredData.length / pageSize)} onClick={() => handlePageChange(null, page + 1)}>
            <FaAngleRight></FaAngleRight>
          </Button>
         
                  </Flex>
        </div>
      </td>
    </tr>
  </tfoot>
      </table>
  </div>)
}
export default DataTable