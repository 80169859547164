import React from 'react';
import {
  Avatar, Menu, MenuButton, Text, MenuList, MenuItem, IconButton, Hide,
  FormControl, Flex, Center, MenuDivider, Box, HStack, VStack, useColorModeValue
} from '@chakra-ui/react';
import { FaChevronDown, FaHamburger } from 'react-icons/fa';
import { Link  } from 'react-router-dom';
import { useAuth } from '../auth/auth';
import Sidebar from './sidebarComponent';
import { FiBell, FiChevronDown } from 'react-icons/fi';

const HeaderDashboard = (props) => {
  // const [isMenuOpen, setIsMenuOpen] = useState(false);
  let auth = useAuth();
  
  return (
    <Flex bg={'#ffffff'} p={3} top={0} left={0} right={0}  width={'100%'}>
          <Hide above='md'>
        <Menu h='max-content'>
            <MenuButton
              as={IconButton}
              aria-label='Options'
              icon={<FaHamburger/>}
              variant='outline'
            />
            <MenuList minHeight='500vh' marginLeft={'-20%'} background='hsl(216, 25%, 95.1%);' width={'256px'}>
              <Sidebar></Sidebar>
            </MenuList>
            </Menu>
          </Hide>
      <Hide below='sm'>
          
            <FormControl>
              {/* <Input placeholder={'Search'} w={500}></Input><Button>Search {auth.user.role === "poster" ? 'Talents' : 'Jobs'}</Button> */}
            </FormControl>
          </Hide>
      
          <Flex w={'100%'} justifyContent={'end'}>
        <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FiBell />}
        />
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}>
              <HStack display={{ base: 'none', md: 'flex' }}
                  alignItems="center"
                  spacing="1px"
                  flexDirection="row"
                  justifyContent = "space-between"
                  padding="6px 15px 6px 7px"
                  gap="4px"
                  height="48px"
                  flex="none"
                  order="2"
                  flexGrow="0">
                <Avatar
                  size={'sm'}
                  src={
                    'https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                  }
                />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2">
                  <Text fontSize="sm" fontWeight="semibold" fontFamily='Poppins'>{auth && (`${auth.user.firstName} ${auth.user.lastName}`)}</Text>
                  
                </VStack>
                {/* <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box> */}
              </HStack>
            </MenuButton>
            {/* <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}>
              <MenuItem>Profile</MenuItem>
              <MenuItem>Settings</MenuItem>
              <MenuItem>Billing</MenuItem>
              <MenuDivider />
              <MenuItem onClick={()=>{ auth.logout()}}>Sign out</MenuItem>
            </MenuList> */}
          </Menu>
        </Flex>
      </Flex>
  );
};

export default HeaderDashboard;
