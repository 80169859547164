import React from "react";
import { useState, createContext, useContext } from "react";
const AuthContext = createContext(null)

export const AuthProvider = ({ children}) => {
    const [user, setUser] = useState(null);

    const login = (data)=> {
       
        setUser(data);
    }
    const logout = () => {
        setUser(null);
        localStorage.removeItem("token");
    }
    return(
        <AuthContext.Provider value={{ user, login, logout}}>
            {children}
        </AuthContext.Provider>
    )
}
export const useAuth = () =>{
    return useContext(AuthContext)
}