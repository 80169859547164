import axios from 'axios';
export class Admin {
  
  changePassword = async (data) => {
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/changePassword`,{
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + localStorage.getItem("token")
        },
      })
      return response.json();
    } catch (error) {
      return error
    }
  }
  createContact = async (data) => {
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/createContact`,{
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + localStorage.getItem("token")
        },
      })
      return response.json();
    } catch (error) {
      return error
    }
  }
  createRequest = async (data) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/createRequest`,{
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + localStorage.getItem("token")
           
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      
      return response.json();
    } catch (error) {
      return error
    }
  }
  createPolicy = async (data) => {
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/createPolicies`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + localStorage.getItem("token")
           
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
        body: data
      })
      return response.json();
    } catch (error) {
      return error
    }
  }
  createResource = async (data) => {
   
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/createResource`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + localStorage.getItem("token")
           
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
        body: data
      })
      return response.json();
    } catch (error) {
      return error
    }
  }
  createUser = async (data) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/register`,{
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
           
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      return response.json();
    } catch (error) {
      return error
    }
  }
  async login(user) {
    localStorage.clear();
    
    try {
      let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, JSON.stringify(user), {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      })
      
      localStorage.setItem('token', `Bearer ${data.token}`);
      return await this.fetchData(data)
      
    } catch (e) {
      return (e.response)
    }
  
  }
  
  
  async fetchData(user) {
  
    try {
      let options = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + user.token
        }
      }
      let datas = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, options)
      if (datas.auth !=="user")
        return datas
      return
    } catch (e) {
      return (e.response)
    }
  }
  

  fetchContact = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/findContact`,{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
           
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      return response.json();
    } catch (error) {
      return error
    }
  }
  fetchContactById = async (id) => {
    if (id){
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/contactById/${id}`,{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + localStorage.getItem("token")
        },
      })
      return response.json();
    } catch (error) {
      return error
      }
    }
  }
  fetchPolicies = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/findPolicy`,{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
           
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      return response.json();
    } catch (error) {
      return error
    }
  }
  fetchPolicyById = async (id) => {
    if (id){
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/policyById/${id}`,{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + localStorage.getItem("token")
        },
      })
      return response.json();
    } catch (error) {
      return error
      }
    }
  }
  fetchResources = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/findResource`,{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
           
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      return response.json();
    } catch (error) {
      return error
    }
  }
  fetchResourceById = async (id) => {
    if (id){
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/resourceById/${id}`,{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + localStorage.getItem("token")
        },
      })
      return response.json();
    } catch (error) {
      return error
      }
    }
  }
  fetchRequests = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/findRequest`,{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
           
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      return response.json();
    } catch (error) {
      return error
    }
  }
  fetchRequestById = async (id) => {
    if (id){
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/requestById/${id}`,{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + localStorage.getItem("token")
        },
      })
      return response.json();
    } catch (error) {
      return error
      }
    }
  }
  fetchTeam = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/findTeam`,{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
           
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      return response.json();
    } catch (error) {
      return error
    }
  }
  
  uploadAssets = async (data) => {
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/uploadFile`, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + localStorage.getItem("token")
        },
        method: 'POST',
        body: data
      })
      return response.json();
    } catch (error) {
      return error
    }
  }
  loadAssets = async (data) => {
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/findFiles/${data}`,{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
           
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      return response.json();
    } catch (error) {
      return error
    }
  }
  deleteData = async (type, row) => {
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/delete/${type}/${row}`,{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
           
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      return response.json();
    } catch (error) {
      return error
    }
  }

unPublish = async (type, row) => {
  
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/unpublish/${type}/${row}`,{
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
         
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    return response.json();
  } catch (error) {
    return error
  }
  }
  editContact = async (data) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/editContact`,{
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + localStorage.getItem("token")
           
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
    
      return response.json();
    } catch (error) {
      return error
    }
  }
  editRequest = async (data) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/editRequest`,{
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + localStorage.getItem("token")
           
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
    
      return response.json();
    } catch (error) {
      return error
    }
  }
  editPolicy = async (data) => {
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/editPolicy`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + localStorage.getItem("token")
           
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
        body: data
      })
      return response.json();
    } catch (error) {
      return error
    }
  }
  editResource = async (data) => {
   
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/editResource`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + localStorage.getItem("token")
           
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
        body: data
      })
      return response.json();
    } catch (error) {
      return error
    }
  }

  editTeam = async (data) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/editTeam`,{
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + localStorage.getItem("token")
           
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
     
      return response.json();
    } catch (error) {
      return error
    }
  }
}