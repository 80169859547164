import React, { useState, useEffect } from 'react';

import { 
  Text,
  SimpleGrid,
  Box, 
  useColorModeValue, 
  FormControl,
  FormLabel, Input,
  Switch,Stack,
   Hide, HStack, Textarea, Button,
  Radio, RadioGroup, Flex, CircularProgress, Checkbox
} from '@chakra-ui/react';
import Sidebar from '../components/sidebarComponent';
import HeaderDashboard from '../components/headerDashboard';
import DeleteModal from '../components/deleteModal';
import Editor from '../components/ckEditor';
import { Admin } from '../services/admin';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UnpublishButton from '../components/unpublishButton';
import DocumentPolicy from '../components/documentPolicy';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/auth';
import UploadImage from '../components/uploadImage';
import SummaryDocument from '../components/summaryDocument';


const CreateResource = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [policy, setPolicy] = useState({ status: "Draft"});
  const [files, setFiles] = useState([]);
  const [sum, setSum] = useState([]);
  const [images, setImages] = useState([]);
  const [result, setResult] = useState({});
  const handleFileChange = (e) => {
    console.log(e.target.name)
    if (e.target.name === "documentPolicy"){
        setFiles(e.target.files)
      }else if (e.target.name === "summary"){
        setSum(e.target.files)
      }else{
        setImages(e.target.files)
      }
  };

  const handleChange = (e) => {
    setPolicy({ ...policy, [e.target.name]: e.target.value });
  }
  
  const submitForm = (e) => {
    e.preventDefault();
    document.querySelector("#loader").style = "display: block";
    policy.userId = auth.user.id;    
    if (window.location.pathname.split('/').length === 3) {
      setPolicy(result => ({ ...result, ...policy }));
    } 
    const formData = new FormData();
    Object.entries(policy).forEach(([key, value]) => {
      formData.append(key, value);
    });
    
    for (let i = 0; i < files.length; i++) {
      formData.append('documentPolicy', files[i]);
    }
    for (let i = 0; i < images.length; i++) {
      formData.append('picture', images[i]);
    }
    for (let i = 0; i < sum.length; i++) {
      formData.append('summary', sum[i]);
    }
    
    const createResource = new Admin();
    if (window.location.pathname.split('/').length === 3) {
      formData.append("id", window.location.pathname.split('/')[2])
      createResource.editResource(formData)
        .then((response) => {
          document.querySelector("#loader").style = "display: none";
          response.statusCode !== 200 ? toast.error(response.message) : toast.success(response.message)
        })
    } else {
      createResource.createResource(formData)
        .then((response) => {
          document.querySelector("#loader").style = "display: none";
          response.statusCode !== 200 ? toast.error(response.message) : toast.success(response.message)
        })
    }

  }
  useEffect(() => {
    const fetchResources = new Admin();
    fetchResources.fetchResourceById(window.location.pathname.split("/")[2])
      .then((response) => {
        response.statusCode !== 200 ? toast.error(response.message) : setPolicy(response.data)    
        
      }) 
    
    },[])
   
  const unPublished = () => {
    const unpublishResource = new Admin();
    unpublishResource.unPublish("resource", window.location.pathname.split("/")[2])
    .then((response) => {
      response.statusCode !== 200 ? toast.error(response.message) : toast.success(response.message)    
    
    }) 
  
  }
  const deleteEntry = () => {
    const deleteResource = new Admin();
    deleteResource.deleteData("resource", window.location.pathname.split("/")[2])
    .then((response) => {
      response.statusCode !== 200 ? toast.error(response.message) : toast.success(response.message)    
      setTimeout(() => navigate("/resource", { replace: true }), 2000)
    }) 
  }
  return (
    <Flex display={'flex'} bg={useColorModeValue('#F5F5F5')} minH={{ base: 'auto', md: "100vh" }} position={'relative'} width={'auto'}>
    <Hide below="md">
        <Sidebar></Sidebar>
    </Hide>
    <ToastContainer></ToastContainer>
      <Flex flexDirection={'column'} w={'100%'} minH={{ base: 'auto', md: "100vh" }}>
         <HeaderDashboard></HeaderDashboard>
        {/* <HeaderDashboard name={auth.user.applicationUser}></HeaderDashboard> */}
        
        <Flex p={5} flexDirection={{ base: "column", md: "row" }}>
        <form onSubmit={submitForm} width="100%" style={{ display: "contents" }}>

          <Flex h="100%" w={{ base: "100%", md: "65%" }}  direction={"column"} mb={5}>
            <Stack>
                            <Box bg={'white'}
              p={10}
              
                              borderRadius={'6px'}
                minH="110vh"
                
                             >
                                  
                            <HStack pb={5} display={'flex'}>
                                <FormControl >
                                    <FormLabel>Title</FormLabel>
                                    <Textarea name={"title"} onChange={handleChange} value={policy.title} ></Textarea>
                              </FormControl>
                      <FormControl fontSize="14px" fontWeight={"500"} as={SimpleGrid} columns='2'
                        justifyContent={'flex-end'} float={'right'} width={"100%"} gap={'2'} display={'-webkit-inline-box'}>
                                    <FormLabel htmlFor='isChecked' display={'flex'} mt={"-44px"}>Publish</FormLabel>
                      <Switch id='isChecked' mt={"-87px"} name={"publish"}
                                   isChecked={policy.status === "Published"}
                                  onChange={(event) => {
                                        handleChange(event);
                                        setPolicy({...policy, status: event.target.checked ? "Published" : "Unpublished"});
                                      }}
                                    />

                                </FormControl>
                          </HStack>
                          <FormControl>
                              <FormLabel fontSize="14px" fontWeight={"500"}>Description</FormLabel>
                              <Editor name={"description"} onChange={handleChange} setPolicy={setPolicy} policy={policy} result={policy.description}></Editor>
                      </FormControl>
                      <SimpleGrid alignItems={'center'}  px={3} justifyContent={'center'} columns={{ base: 1, md: 2}} spacing={5} mt={20}>
                      <FormControl>
                    <UploadImage onChange={handleFileChange} result={policy} setPolicy={setPolicy}></UploadImage>
                    <Input type="text"  name={'picture'} onChange={handleChange} value={policy.picture} /> 
                        </FormControl> 
                    <FormControl>
                      <SummaryDocument name="summary" onChange={handleFileChange} result={policy} setPolicy={setPolicy}></SummaryDocument>
                  
                    <Input type="text" name="summary" value={policy.summary} onChange={handleChange}  /> 
                        </FormControl>    
                         <FormControl>
                    <DocumentPolicy name="documentPolicy" onChange={handleFileChange} result={policy} setPolicy={setPolicy}></DocumentPolicy>
                    <Input type="text" name="documentPolicy" value={policy.documentPolicy} onChange={handleChange} /> 
                        </FormControl>        
                  
                  
                      </SimpleGrid>
                      </Box>
                      {/* </GridItem> */}
                    {/* <GridItem  top={0}
                    borderRadius={'6px'}     height={'fit-content'}
                    fontFamily={'Manrope'}  
                  > */}
              </Stack>
          </Flex>
          <Flex h="100%" w={{ base: "100%", md: "30%" }} direction={"column"} float="right" ml={{ base:"1%", md:"5%"}}>
            <Stack >
                    <Box bg={'white'} p={10} >
                          <Text
                          fontFamily='Manrope'
                          fontStyle='normal'
                          fontWeight='600'
                          fontSize='16.32px'
                          lineHeight="22px"
                          color="#374151"
                          >Information</Text>
                          <FormControl mb={5}>
                              <FormLabel
                                fontWeight="400"
                                fontSize="13"
                                lineHeight="17px"
                                color="#374151">
                              Last Update</FormLabel>
                              <Input type="datetime" readOnly value={policy.updatedAt} ></Input>
                          </FormControl>
                          <FormControl mb={5}>
                              <FormLabel  fontWeight="400"
                                fontSize="13"
                                lineHeight="17px"
                                color="#374151">By</FormLabel>
                              <Input type="text" readOnly value={ policy.userId && (policy.userId.firstName+" "+policy.userId.lastName)} ></Input>
                          </FormControl>
                          <FormControl mb={5}>
                              <FormLabel  fontWeight="500"
                                fontSize="14px"
                                lineHeight="17px"
                  color="#374151">Categories</FormLabel>
                    <RadioGroup fontSize="10px" onChange={(event) => {

                                        setPolicy({...policy, category: event});
                                    }} >
                      <Stack >
                      <Radio value='Technology Policies'>Technology Policies</Radio>
                            <Radio value='Regulations and Compliance Guidelines'>Regulations and Compliance Guidelines</Radio>
                            <Radio value='API White Papers and Memoranda'>API White Papers and Memoranda</Radio>
                      </Stack>
                    </RadioGroup>
                      
                  </FormControl>
                  <FormControl>
                  <Checkbox
                      onChange={(event) => {
                        event.target.checked ? setPolicy({...policy, featured: true}) : setPolicy({...policy, featured: false})
                      }}
                    >
                      Featured
                    </Checkbox>
                  </FormControl>
                  <CircularProgress isIndeterminate id={"loader"}  display={'none'}></CircularProgress>
                  <Button
                    type="submit"
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    padding="12.6933px 18.1333px"
                    gap="9.07px"
                    width="100%"
                    color="white"
                    height="45.39px"
                    background="#4FB58B"
                    borderRadius="6px"
                    mt={'3'}

                  >Save</Button>
                  {window.location.pathname.split("/")[2] ?
                    <HStack mt={3}>
                      <UnpublishButton onClick={unPublished}></UnpublishButton>
                      <DeleteModal onClick={deleteEntry}></DeleteModal>
                    </HStack> : ""}
                </Box>
              {/* </GridItem> */}
              </Stack>
            </Flex>
          </form>
          </Flex>
          
      </Flex>
    </Flex>
  );
};

export default CreateResource;
