import { Button, Flex, propNames } from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";


const PageNavigation = (props) => {
  let navigate = useNavigate();
  const [visiblePages, setVisiblePages] = useState([
    props.currentPage - 2,
    props.currentPage - 1,
    props.currentPage,
    props.currentPage + 1,
    props.currentPage + 2,
  ]);

  const handlePageClick = (page) => {
    navigate(`/media/${page}`, {replace: true})
    
    setVisiblePages([
      page - 2,
      page - 1,
      page,
      page + 1,
      page + 2,
    ]);
  };

  return (
    <Flex display="flex" alignItems={'right'} style={{ float: 'right'}}>
      {props.currentPage > 3 && (
        
          <Button background="#F1F7FF" borderRadius={'8px'} onClick={() => handlePageClick(1)}>1</Button>
        
      )}
      {props.currentPage > 4 && (
        
         <Button background="#F1F7FF" borderRadius={'8px'} disabled>...</Button>
        
      )}
      {visiblePages
        .filter((page) => page > 0 && page <= props.totalPages)
        .map((page) => (
          
            <Button background="#F1F7FF" borderRadius={'8px'}
              onClick={() => handlePageClick(page)}
                    isCurrent={page === props.currentPage}
                    key={page}
            >
              {page}
            </Button>
          
        ))}
      {props.currentPage < props.totalPages - 3 && (
        
        <Button background="#F1F7FF" borderRadius={'8px'} disabled>...</Button>
        
      )}
   {props.currentPage < props.totalPages - 2 && (
       <Button background="#F1F7FF" borderRadius={'8px'} onClick={() => handlePageClick(props.totalPages)}>
            {props.totalPages}
          </Button>
      )}
    </Flex>
  );
};

export default PageNavigation;
