import React, { useState} from 'react';
import { useAuth } from '../auth/auth';
import { 
    Text,
  Box, Stack,
  useColorModeValue, 
  Flex, FormControl,
  FormLabel, Input, CircularProgress,
  Hide, HStack, Button
} from '@chakra-ui/react';
import Sidebar from '../components/sidebarComponent';
import HeaderDashboard from '../components/headerDashboard';
import { Admin } from '../services/admin';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Profile = () => {
  let auth = useAuth();
  const [user, setUser] = useState({});
  
    const handleChange = (e) => {
      setUser({ ...user, [e.target.name]: e.target.value });
    }

    const submitForm = async(e) => {
      e.preventDefault();
      document.querySelector("#loader").style = "display:block";
      
     
      const password = new Admin();
     
      password.changePassword(user)
          .then((response) => {
            document.querySelector("#loader").style = "display:none";
            response.statusCode !== 200 ? toast.error(response.message) : toast.success(response.message)
        
          })
    
    }
  return (
    <Flex display={'flex'} bg={useColorModeValue('#F5F5F5')} minH={{ base: 'auto', md: "100vh" }} position={'relative'} width={'auto'}>
    <Hide below="md">
        <Sidebar></Sidebar>
    </Hide>

      <Flex flexDirection={'column'} w={'100%'} minH={{ base: 'auto', md: "100vh" }}>
         <HeaderDashboard></HeaderDashboard>
        {/* <HeaderDashboard name={auth.user.applicationUser}></HeaderDashboard> */}
        
        <Flex p={5} flexDirection={{ base: "column", md: "row" }}>
          <ToastContainer></ToastContainer>
        <form onSubmit={submitForm} width="100%" style={{ display: "contents" }}>

          <Flex h="100%" w={{ base: "100%", md: "65%" }}  direction={"column"} mb={5}>
            <Stack>
                            <Box bg={'white'}
              p={10}
              
                              borderRadius={'6px'}
                minH="100vh"
                
                             >
                            <HStack pb={5}>
                                <FormControl fontSize="14px" fontWeight={"500"}>
                                    <FormLabel>First Name</FormLabel>
                                    <Input value={auth.user.firstName} readOnly />
                              </FormControl>
                              <FormControl fontSize="14px" fontWeight={"500"}>
                                    <FormLabel>Last Name</FormLabel>
                                    <Input value={auth.user.lastName} readOnly  />
                              </FormControl>
                          </HStack>
                          <HStack pb={5}>
                                <FormControl fontSize="14px" fontWeight={"500"}>
                                    <FormLabel>Email</FormLabel>
                                    <Input value={auth.user.email} readOnly />
                              </FormControl>
                              <FormControl fontSize="14px" fontWeight={"500"}>
                              <FormLabel>Username</FormLabel>
                                    <Input value={auth.user.email} readOnly/>
                                </FormControl>
                          </HStack>
                          <HStack pb={5}>
                                <FormControl fontSize="14px" fontWeight={"500"}>
                                    <FormLabel>Password</FormLabel>
                                    <Input  name={"password"} onChange={handleChange}/>
                              </FormControl>
                              <FormControl fontSize="14px" fontWeight={"500"}>
                              <FormLabel>Confirm Password</FormLabel>
                                    <Input name={"newpassword"} onChange={handleChange} />
                                </FormControl>
                          </HStack>
               
                      </Box>
            </Stack>
          </Flex>
     
                  <Flex h="100%" w={{ base: "100%", md: "30%" }} direction={"column"} float="right" ml={{ base:"1%", md:"5%"}}>
            <Stack>
                    <Box bg={'white'} p={10} >
                          <Text
                          fontFamily='Manrope'
                          fontStyle='normal'
                          fontWeight='600'
                          fontSize='16.32px'
                          lineHeight="22px"
                          color="#374151"
                          >Information</Text>
                  
                  <CircularProgress isIndeterminate id={"loader"}  display={'none'}></CircularProgress>
              <Button
                  type={"submit"}
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    padding="12.6933px 18.1333px"
                    gap="9.07px"
                    width="100%"
                    color="white"
                    height="45.39px"
                    background="#4FB58B"
                    borderRadius="6px"
                          >Save</Button>
                
                </Box>
                </Stack>
              {/* </GridItem> */}
              </Flex>
              </form>
          </Flex>
          
      </Flex>
    </Flex>
  );
};

export default Profile;
