import React, { useState } from 'react';
import { Box, Flex, Image, Stack, Center } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FaChevronDown} from 'react-icons/fa';

// import { BsFillFilePlayFill } from 'react-icons/bs';
import { useAuth } from '../auth/auth';
import OnboardLogo from './OnboardLogo';
let contact = window.location.origin+'/files/contact.svg';
let document = window.location.origin+'/files/document-text.svg';
let resource = window.location.origin+'/files/document.svg';
let request = window.location.origin+'/files/document-forward.svg';
let teams = window.location.origin+'/files/teams.svg';
let media = window.location.origin+'/files/uploads.svg';
let profile = window.location.origin+'/files/profile-circle.svg';
let logout = window.location.origin+'/files/logout.svg';

const Sidebar = () => {
  let auth = useAuth();
 
  const [sidebarItems, setSidebarItems] = useState( [
    { label: 'Contact Submission', icon: contact, href:"/submission", items:[] },
    { label: 'Policies', icon: document, href:"/policies", items:[] },
    { label: 'Resources', icon: resource, href:"/resource", items:[] },
    { label: 'Subscription Requests', icon: request, href:"/requests", items:[] },
    { label: 'Team Members', icon: teams, href:"/team", items:[] },
    { label: 'Media', icon: media, href:"/media", items:[] },
  ]
  );
  const [activePage, setActivePage] = useState('dashboard');
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  // const addSidebarItem = (item) => {
  //   setSidebarItems([...sidebarItems, item]);
  // }

  // const removeSidebarItem = (item) => {
  //   setSidebarItems(sidebarItems.filter(sidebarItem => sidebarItem.label !== item.label));
  // }
  const showActive = (item) => {
    console.log()
    let activeColor;
    if (item.href === window.location.pathname) {
      activeColor = { color: 'white', bg: '#233065' }
    } else {
      activeColor = { color: '#929EAE', bg: 'transparent' }
    }

    return activeColor;
  }
  const showSubscribed = (item) => {
    if ((item.label === "Display") && (!auth.user.subscribed)) {
        return "none"      
    } else {
      return "block"
    }
  }
  const toggleSubmenu = (item) => {
    if (activeSubmenu === item.label) {
      setActiveSubmenu(null);
    } else {
      setActiveSubmenu(item.label);
    }
  }

  return (
    <Flex
      bg={'#ffffff'}
    transition="3s ease"
    // borderRight="1px"
    // borderRightColor={useColorModeValue('gray.200', 'gray.700')}
    fontFamily='Kumbh Sans'
    fontSize='14px'
    fontStyle="normal"
    fontWeight="600"
    lineHeight={'17px'}  
    alignItems="flex-start"
    w={{ base: 'full', md: '18rem' }}
    position = 'relative'
    minHeight="100vh"
    
      top={0}
      
    >
    
        <Flex flexDirection={'column'} alignContent={'center'}
        alignItems={'center'}
        width="17rem"
        
      >
        <Center mx={{ base: 1, md: "-4" }}>
            <OnboardLogo></OnboardLogo>
          {/* <Image src={'/logo.png'} width='150px' /> */}
        </Center>
        <Flex alignItems='flex-start' flexDirection={'column'}
          padding="0px"
          gap="2"
        >
          {sidebarItems.map((item, index) => (
        
          <Link to={item.href} key={index}
              style={{ textDecoration: 'none' }} fontWeight={600}
              gap="6"
              fontSize={'14px'}
              _focus={{ boxShadow: 'none' }}
            onClick={() => toggleSubmenu(item)}
          my={1}>
          <Flex
            flexDirection="row"
            alignItems="center"
            padding="14px 81px 14px 15px"
            gap="12px"
            height="48px"
            background="#233065"
            borderRadius="8px"
            flex="none"
            order="0"
            flexGrow="0"
            align="center"
            px="5"
            py="3"
            mx="4"
            role="group"
            cursor="pointer"
            _hover={{
              color: '#233065',
              bg: 'white',
                }}
            w={'15rem'}
            bg={showActive(item).bg}
            color={showActive(item).color}
            >
            {item.icon && (
              <Image
                mr="3"
                fontSize="16"
                _groupHover={{
                  color: '#233065',
                    }}
                src={item.icon}
              />
            )}
             <span> {item.label}</span>
              {item.items.length > 0 && (
              <FaChevronDown float='right' style={{marginLeft: "10px", right:0}} ></FaChevronDown>
            )}
            </Flex>
         
            {item.items.length > 0 && activeSubmenu === item.label && (
            <Stack>
              {
                item.items.map(item => (
                  <Link to={item.href} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }} className={item.label === activePage ? 'active' : ''} >
                    <Flex
                          align="center"
                          px="5"
                          py="3"
                          mx="4"
                          borderRadius="lg"
                          role="group"
                          cursor="pointer"
                          _hover={{
                            color: '#233065',
                            bg: 'white',
                          }}
                          w={'15rem'}
                          bg={showActive(item).bg}
                          color={showActive(item).color}
                          >
                                {item.label}
                                    
                          </Flex>
                  </Link>
                ))
              }
              </Stack>
            )}
                
          </Link>
          
    
          ))}
          
          <Box mt={20} w={"100%"} style={{borderTop: "1px solid #929EAE"}}></Box>
               
          <Link to="/profile" 
            style={{ textDecoration: 'none' }}
            fontWeight={600}
            gap="6"
            fontSize={'16px'}
              _focus={{ boxShadow: 'none' }}
          my={1}>
          <Flex
            flexDirection="row"
            alignItems="center"
            padding="14px 81px 14px 15px"
            gap="12px"
            height="48px"
            borderRadius="8px"
            flex="none"
            order="0"
            flexGrow="0"
            align="center"
            px="5"
            py="3"
            mx="4"
            role="group"
              cursor="pointer"
              w={'15rem'}
            _hover={{
              color: '#233065',
              bg: 'white',
            }}
            bg={ "/profile" === window.location.pathname ? '#233065' : 'white' }            
            color={ "/profile" === window.location.pathname ? 'white' : '#929EAE' }
            >
            
              <Image
                mr="3"
                fontSize="16"
                _groupHover={{
                  color: '#233065',
                }}
                src={profile}
              />
            <span> Profile</span>
            </Flex>
         
        
          
          </Link>
          <Box
            onClick={(event) => {
              console.log(event)
              auth.logout();	}}
            style={{ textDecoration: 'none' }}
            fontWeight={600}
            gap="6"
            fontSize={'16px'}
              _focus={{ boxShadow: 'none' }}
          my={1}>
          <Flex
            flexDirection="row"
            alignItems="center"
            padding="14px 81px 14px 15px"
            gap="12px"
            height="48px"
            borderRadius="8px"
            flex="none"
            order="0"
            flexGrow="0"
            align="center"
            px="5"
            py="3"
            mx="4"
            role="group"
              cursor="pointer"
              color="red"
            _hover={{
              color: '#233065',
              bg: 'white',
            }}
            
            >
            
              <Image
                mr="3"
                fontSize="16"
                _groupHover={{
                  color: '#233065',
                }}
                src={logout}
              />
            <span> Logout</span>
            </Flex>
         
        
          
          </Box>
          

          
        </Flex>
     
        </Flex>
    
    </Flex>
    
  );
}

export default Sidebar;
