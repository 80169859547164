import React, { useState, useEffect } from 'react';
import { useAuth } from '../auth/auth';
import { 
  Text, Box, 
  useColorModeValue, Flex, FormControl,
  FormLabel, Input, Stack,
  Hide, HStack, Textarea, Button,
  CircularProgress
} from '@chakra-ui/react';
import Sidebar from '../components/sidebarComponent';
import HeaderDashboard from '../components/headerDashboard';
import DeleteModal from '../components/deleteModal';
import { Admin } from '../services/admin';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UnpublishButton from '../components/unpublishButton';
import { useNavigate } from 'react-router-dom';


const CreateContact = () => {
  let auth = useAuth();
  const navigate = useNavigate()
  
  const [contact, setContact] = useState({ });
  const [result, setResult] = useState({});
     
    const handleChange = (e) => {
      setContact({ ...contact, [e.target.name]: e.target.value });
    }
    
    const submitForm = async(e) => {
      e.preventDefault();
      document.querySelector("#loader").style = "display:block";
      
      contact.userId = auth.user.id;
      
      const createContact = new Admin();
      if (window.location.pathname.split('/').length === 3) {
        setContact({ ...contact, "id": `${window.location.pathname.split('/')[2]}`})
        createContact.editContact(contact)
          .then((response) => {
            document.querySelector("#loader").style = "display: none";
            response.statusCode !== 200 ? toast.error(response.message) : toast.success(response.message)
          })
      } else {
        createContact.createContact(contact)
          .then((response) => {
            document.querySelector("#loader").style = "display:none";
            response.statusCode !== 200 ? toast.error(response.message) : toast.success(response.message)
        
          })
      }
    }
  useEffect(() => {
    const fetchContact = new Admin();
    fetchContact.fetchContactById(window.location.pathname.split("/")[2])
      .then((response) => {
        response.statusCode !== 200 ? toast.error(response.message) : setContact(response.data)    
      }) 
    
    },[result])
    const unPublished = () => {
      const unpublishResource = new Admin();
      unpublishResource.unPublish("contact", window.location.pathname.split("/")[2])
      .then((response) => {
        response.statusCode !== 200 ? toast.error(response.message) : toast.success(response.message)    
      
      }) 
    
    }
    const deleteEntry = () => {
      const deleteResource = new Admin();
      deleteResource.deleteData("contact", window.location.pathname.split("/")[2])
      .then((response) => {
        response.statusCode !== 200 ? toast.error(response.message) : toast.success(response.message)    
        setTimeout(() => navigate("/submission", { replace: true }), 2000)
      }) 
    }
  return (
    <Flex display={'flex'} bg={useColorModeValue('#F5F5F5')} minH={{ base: 'auto', md: "100vh" }} position={'relative'} width={'auto'}>
    <Hide below="md">
        <Sidebar></Sidebar>
    </Hide>
    <ToastContainer></ToastContainer>
      <Flex flexDirection={'column'} w={'100%'} minH={{ base: 'auto', md: "100vh" }}>
         <HeaderDashboard></HeaderDashboard>
        {/* <HeaderDashboard name={auth.user.applicationUser}></HeaderDashboard> */}
        
        <Flex p={5} flexDirection={{ base: "column", md: "row" }}>
          <form onSubmit={submitForm} width="100%" style={{ display: "contents" }}>
          <Flex h="100%" w={{ base: "100%", md: "65%" }}  direction={"column"} mb={5}>
            <Stack>
                <Box bg={'white'} p={10} borderRadius={'6px'}  minH="100vh">
                    <HStack pb={8}>
                      <FormControl>
                        <FormLabel>First name</FormLabel>
                            <Input name='firstName' onChange={handleChange} value={contact.firstName} />
                        </FormControl>
                        <FormControl fontSize="14px" fontWeight={"500"}>
                          <FormLabel>Last name</FormLabel>
                            <Input name='lastName' onChange={handleChange} value={contact.lastName}  />
                          </FormControl>
                    </HStack>
                    <HStack pb={8}>
                      <FormControl fontSize="14px" fontWeight={"500"}>
                        <FormLabel>Email</FormLabel>
                          <Input name='email' onChange={handleChange}  value={contact.email} />
                        </FormControl>
                        <FormControl fontSize="14px" fontWeight={"500"}>
                          <FormLabel>Company Name</FormLabel>
                            <Input name='companyName' onChange={handleChange} value={contact.companyName}  />
                          </FormControl>
                        </HStack>
                        <HStack pb={8}>
                          <FormControl fontSize="14px" fontWeight={"500"}>
                            <FormLabel>Country</FormLabel>
                              <Input name={'country'} onChange={handleChange} value={contact.country} />
                          </FormControl>
                          <FormControl fontSize="14px" fontWeight={"500"}>
                            <FormLabel>Website URL</FormLabel>
                              <Input name={'webURL'} onChange={handleChange} value={contact.webURL}  />
                            </FormControl>
                        </HStack>
                        <FormControl>
                            <FormLabel fontSize="14px" fontWeight={"500"}>Message</FormLabel>
                              <Textarea minH={'30vh'} name="message" onChange={handleChange} value={contact.message} ></Textarea>
                        </FormControl>
              </Box>

                          </Stack>
          </Flex>
          <Flex h="100%" w={{ base: "100%", md: "30%" }} direction={"column"} float="right" ml={{ base:"1%", md:"5%"}}>
            <Stack >
                    <Box bg={'white'} p={10} >
                          <Text
                          fontFamily='Manrope'
                          fontStyle='normal'
                          fontWeight='600'
                          fontSize='16.32px'
                          lineHeight="22px"
                          color="#374151"
                          >Information</Text>
                          <FormControl mb={5}>
                              <FormLabel
                                fontWeight="400"
                                fontSize="13"
                                lineHeight="17px"
                                color="#374151">
                              Last Update</FormLabel>
                              <Input type="datetime" name="updatedAt" readOnly value={contact.updatedAt} ></Input>
                          </FormControl>
                          <FormControl mb={5}>
                              <FormLabel  fontWeight="400"
                                fontSize="13"
                                lineHeight="17px"
                                color="#374151">By</FormLabel>
                              <Input type="text" readOnly value={ contact.userId && (contact.userId.firstName+" "+contact.userId.lastName)} ></Input>
                          </FormControl>
                  <CircularProgress isIndeterminate id={"loader"} color={"blue"} style={{ display: "none" }}></CircularProgress>
                  <Button
                    type='submit'
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    padding="12.6933px 18.1333px"
                    gap="9.07px"
                    width="100%"
                    color="white"
                    height="45.39px"
                    background="#4FB58B"
                    borderRadius="6px"
                          >Save</Button>
                          {window.location.pathname.split("/")[2] ?
                    <HStack mt={3}>
                      <UnpublishButton onClick={unPublished}></UnpublishButton>
                      <DeleteModal onClick={deleteEntry}></DeleteModal>
                    </HStack> : ""}
                </Box>
              {/* </GridItem> */}
              </Stack>
            </Flex>
            </form>
          </Flex>
          
      </Flex>
    </Flex>
  );
};

export default CreateContact;
