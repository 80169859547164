import React from 'react';
import {
  Button, Text, Box, Input, Image
} from '@chakra-ui/react';
let image = window.location.origin+'/files/image.svg'

const UploadImage = (props) => {
  
  return (
                       
    <Box
    >
      <Text
      fontFamily="Manrope"
      fontStyle="normal"
      fontWeight="600"
      fontSize="16px"
      lineHeight="20px"
      color="#233065"
      mb={5}    
      >Upload Picture</Text>
      <>
          {props.result && (props.result.picture ?
              <a href={props.result.picture ? `${process.env.REACT_APP_API_URL}/upload/${props.result.picture}` : "#"} target="_blank" rel="noopener noreferrer">
          <Button>{props.result.picture ? "View Image" : ""} </Button></a> : "")}
      
      {/* {props.result && (props.result.picture) ? props.setPolicy({ ...props.policy, "picture": props.result.picture }) : ""} */}
        
      </>
  <Box
    mt={'5'}
    boxSizing="border-box"
    display="flex"
    flexDirection="column"
    alignItems="center"
    padding="22px 26px 26px"
    gap="14px"
    height="144px"
    border="2px dashed #D1D5DB"
              borderRadius="6px"
              cursor={'pointer'}
              
          >
                
                  <Image src={image} height="60px"></Image>
             
              <Text
                  fontFamily="Manrope"
                  fontStyle="normal"
                  fontWeight="600"
                  fontSize="16px"
                  lineHeight="20px"
                  color="#233065"
                  textAlign={'center'}
                  mb={5}
              >Click to upload a picture or drag and drop
                  PNG, JPG, GIF up to 10MB
                </Text>
        <Input type={'file'} name={"picture"}
                  width={'auto'}
                  height="164px" opacity={0}
                  position={'absolute'}
                  cursor={'pointer'}
                  marginTop={'-10px'}
    //   top={0} bottom={0}
      onChange={props.onChange} />
      </Box>

      </Box>

                  
  
  )
}
export default UploadImage