import React, {useState} from 'react';
import {
  Button, Box,
  Text, Center, Icon
} from '@chakra-ui/react';
import { FaArrowUp } from 'react-icons/fa';

const UnpublishButton = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
 
            <Button
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                          alignItems="center"
                          padding="12.6933px 30.8267px 12.6933px 31.7333px"
                          gap="6px"       
                          w="50%"       
                          fontSize={'10px'}
                          borderRadius="6px"
                          border={'1px solid #D1D5DB'}
                          onClick={props.onClick}
      
      >
                              <Icon as={FaArrowUp}/> Unpublish
                              </Button>
    </>
  )
}
export default UnpublishButton