import React, { useState, useEffect } from 'react';
import { 
  SimpleGrid, 
  Box,
  useColorModeValue, 
 Button, Flex, Hide, Text, Input, Image,Icon
} from '@chakra-ui/react';

import Sidebar from '../components/sidebarComponent';
import HeaderDashboard from '../components/headerDashboard';
import { FaUpload } from 'react-icons/fa';
import PageNavigation from '../components/Pagination';
import { Admin } from '../services/admin';
import document from '../files/document-text.svg';
import image from '../files/image.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Media = () => {
 
  const [files, setFiles] = useState([]);
  const [loadFiles, setLoadFiles] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  
  // const pageSize = 20;

  // const getPaginatedFiles = () => {
  //   const startIndex = (currentPage - 1) * pageSize;
  //   const endIndex = startIndex + pageSize;
  //   return files.slice(startIndex, endIndex);
  // };
  

  const handleFileChange = (e) => {
    setFiles(e.target.files);
    console.log(e.target.files)
    let formData = new FormData();
      console.log(files)
    for (let i = 0; i < files.length; i++) {
         formData.append('files', files[i]);
    }
    
    const createUpload = new Admin();
      
    createUpload.uploadAssets(formData)
    .then((response) => {
      response.statusCode !== 200 ? toast.error(response.message) : toast.success(response.message)
    })

  };

  useEffect(() => {
    let loadUpload = new Admin();
    let mediaId = Number(`${window.location.pathname.split('/').pop()}`);
    let count;
    typeof (mediaId) == "number" ? count = mediaId : count = 1
    
      loadUpload.loadAssets(count)
        .then((response) => {
          setLoadFiles(response.files);
          setTotalPages(response.totalPages);
      
        })
        .catch((error) => {
          console.error(error);
        });
    
  },[loadFiles])

  const isType =(filePath)=> {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg'];
    const docExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];
    const extension = filePath.split('.').pop().toLowerCase();
  
    if (imageExtensions.includes(extension)) {
      return 'image';
    } else if (docExtensions.includes(extension)) {
      return 'document';
    } else {
      return 'unknown';
    }
  }
  
  

  return (
    <Box display={'flex'} bg={useColorModeValue('#F5F5F5')} minH="100vh" position={'relative'} width={'auto'}>
    <Hide below="md">
        <Sidebar></Sidebar>
    </Hide>
    <ToastContainer></ToastContainer>
      <Box flexDirection={'column'} w={'100%'} minH="100vh">
         <HeaderDashboard></HeaderDashboard>
        {/* <HeaderDashboard name={auth.user.applicationUser}></HeaderDashboard> */}
        <Box m={10} p={6} borderRadius={'6px'}  bg={'white'} minH={'100vh'}>

                <Flex display="flex" alignItems={'flex-end'} float={'right'} w={150}> 
                      <Button
                
            >
               <Icon as={FaUpload} /> <Input type={'file'} name={"policy"}
                ml={5}
                height="100%" opacity={0}
                position={'absolute'}
                cursor={'pointer'}
                top={0} bottom={0}
                onChange={handleFileChange}
              />Upload Assets</Button>
                  </Flex>
                  <SimpleGrid alignItems={'center'} ml={'-10'}  px={10} justifyContent={'center'} columns={{ base: 1, md: 3}} spacing={10} mt={20}>
            {loadFiles && loadFiles.map((file, index) => (
              <Box
              >
                <Text
                  fontFamily="Manrope"
                  fontStyle="normal"
                  fontWeight="600"
                  fontSize="16px"
                  lineHeight="20px"
                  color="#233065"
                  mb={5}
                >{isType(file.path) === 'document' ? 'Demo Policy Document' : 'Demo Policy Image'}
                </Text>
                <Box
                  boxSizing="border-box"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  padding="22px 26px 26px"
                  gap="14px"
                  width="366px"
                  height="144px"
                  border="2px dashed #D1D5DB"
                  borderRadius="6px"
                >
                  {isType(file.path) === 'document' ?
                    <Image src={document} height="60px"></Image> : <Image src={image} height="60px"></Image>}
                  
                  
                  <a href={`${process.env.REACT_APP_API_URL}/upload/${file.path}`}  target="_blank" rel="noreferrer"> <Button>View</Button> </a>
                </Box>
                <Text>Uploaded on: {file.createdAt}</Text>

              </Box>
                 ))}
       
                  <Flex>
                          {/* <PageNavigation currentPage={5} totalPages={20}></PageNavigation> */}
                          </Flex>
          </SimpleGrid>
          <Text mt={5}>{ isNaN( Number(`${window.location.pathname.split('/').pop()}`)) ? "Page 1" : `Page ${window.location.pathname.split('/').pop()}`}</Text>
          <PageNavigation totalPages={totalPages} currentPage={1}></PageNavigation>
        </Box>
      </Box>
    </Box>
  );
};

export default Media;
