import React, { useState } from 'react';
import { Button,  Menu,
    MenuButton,
    MenuList,
    MenuItem, } from "@chakra-ui/react";


const DotMenu = (props) => {

const [moreMenuOpen, setMoreMenuOpen] = useState(false);
const handleMoreMenuClose = () => setMoreMenuOpen(false);
const handleMoreMenuOpen = () => setMoreMenuOpen(true);
    

return (
    <>

        
        <Menu>
  <MenuButton> <span role="img" aria-label="more options" style={{ fontSize: '20px' }}>
    ⋮
  </span></MenuButton>
        <MenuList>
                <MenuItem onClick={(event) => {
                    event.stopPropagation();
                    props.handleEdit();
                    handleMoreMenuClose();
                }}>Edit</MenuItem>
                <MenuItem onClick={(event) => {
                    event.stopPropagation();
                    props.handleDelete();
                    handleMoreMenuClose();
                    }}>Delete</MenuItem>
        </MenuList>
        </Menu>
    </>
    );
}

export default DotMenu;