import React, { Component } from 'react';

import Editors from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
class Editor extends Component {
    render() {
        return (
            <div style={{maxWidth: '520px'}}>
            
                <CKEditor
                    editor={Editors}
                    data={this.props.result || " "}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', editor );
                        editor.ui.view.editable.element.style.minHeight = "40vh";
                    }}
                    onChange={(event, editor) => {
                        editor.ui.view.editable.element.style.minHeight = "40vh";
                        const data = editor.getData();
                        // pass the data to the parent component
                        // this.props.onChange(data);
                        this.props.setPolicy({
                            ...this.props.policy,
                            description: data
                        });
                        console.log( { event, editor, data } );
                    }}
                    onBlur={(event, editor) => {
                        editor.ui.view.editable.element.style.minHeight = "40vh";
                        console.log( 'Blur.', editor );
                    }}
                    onFocus={(event, editor) => {
                        editor.ui.view.editable.element.style.minHeight = "40vh";
                        console.log( 'Focus.', editor );
                    }}
                />
            </div>
        );
    }
}

export default Editor;