import React, { useState, useEffect } from 'react';
import { 
    Text,
  Box, 
  useColorModeValue, 
  Flex, FormControl,
  FormLabel, Input,
  Stack,
  Hide, HStack, Button,
  CircularProgress
} from '@chakra-ui/react';
import Sidebar from '../components/sidebarComponent';
import HeaderDashboard from '../components/headerDashboard';
import DeleteModal from '../components/deleteModal';
import { Admin } from '../services/admin';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UnpublishButton from '../components/unpublishButton';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/auth';


const CreateRequest = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  
  const [request, setRequest] = useState({});
  
    
    const handleChange = (e) => {
      setRequest({ ...request, [e.target.name]: e.target.value });
    }
    
  const submitForm = async (e) => {
      
    e.preventDefault();
    request.userId = auth.user.id;
    document.querySelector("#loader").style = "display: block";
      const CreateRequest = new Admin();
    if (window.location.pathname.split('/').length === 3) {
      setRequest({ ...request, "id": `${window.location.pathname.split('/')[2]}`})
      CreateRequest.editRequest(request)
        .then((response) => {
          document.querySelector("#loader").style = "display: none";
          response.statusCode !== 200 ? toast.error(response.message) : toast.success(response.message)
        })
    } else {
      request.userId = auth.user.id;
      CreateRequest.createRequest(request)
        .then((response) => {
          document.querySelector("#loader").style = "display: none";
          response.statusCode !== 200 ? toast.error(response.message) : toast.success(response.message)
        })
    }
  }
  useEffect(() => {
    const fetchRequest = new Admin();
    fetchRequest.fetchRequestById(window.location.pathname.split("/")[2])
      .then((response) => {
        response.statusCode !== 200 ? toast.error(response.message) : setRequest(response.data)    
        
      }) 
    
    },[])
     
    const unPublished = () => {
      const unpublishResource = new Admin();
      unpublishResource.unPublish("request", window.location.pathname.split("/")[2])
      .then((response) => {
        response.statusCode !== 200 ? toast.error(response.message) : toast.success(response.message)    
      
      }) 
    
    }
    const deleteEntry = () => {
      const deleteResource = new Admin();
      deleteResource.deleteData("request", window.location.pathname.split("/")[2])
      .then((response) => {
        response.statusCode !== 200 ? toast.error(response.message) : toast.success(response.message)    
        setTimeout(() => navigate("/requests", { replace: true }), 2000)
      }) 
    }

  return (
    <Flex display={'flex'} bg={useColorModeValue('#F5F5F5')} minH={{ base: 'auto', md: "100vh" }} position={'relative'} width={'auto'}>
    <Hide below="md">
      
        <Sidebar></Sidebar>
    </Hide>
    <ToastContainer></ToastContainer>
      <Flex flexDirection={'column'} w={'100%'} minH={{ base: 'auto', md: "100vh" }}>
         <HeaderDashboard></HeaderDashboard>
        {/* <HeaderDashboard name={auth.user.applicationUser}></HeaderDashboard> */}
        
        <Flex p={5} flexDirection={{ base: "column", md: "row" }}>
        <form onSubmit={submitForm} width="100%" style={{ display: "contents" }}>
          <Flex h="100%" w={{ base: "100%", md: "65%" }}  direction={"column"} mb={5}>
            <Stack>
            <Box bg={'white'}
              p={10}
              
                              borderRadius={'6px'}
                minH="100vh"
                
                             >
                            <HStack pb={5}>
                                <FormControl fontSize="14px" fontWeight={"500"}>
                                    <FormLabel>Fullame</FormLabel>
                      <Input name="fullName" onChange={handleChange} value={request.fullName} />
                              </FormControl>
                              <FormControl fontSize="14px" fontWeight={"500"}>
                                    <FormLabel>Email</FormLabel>
                                    <Input name="email" onChange={handleChange} value={request.email} />
                              </FormControl>
                          </HStack>
                          <HStack pb={5}>
                                <FormControl fontSize="14px" fontWeight={"500"}>
                                    <FormLabel>Phone Number</FormLabel>
                                    <Input name="phoneNumber" onChange={handleChange}  value={request.phoneNumber} />
                              </FormControl>
                              <FormControl fontSize="14px" fontWeight={"500"}>
                                    
                                </FormControl>
                </HStack>
                </Box>
                          </Stack>
          </Flex>
          <Flex h="100%" w={{ base: "100%", md: "30%" }} direction={"column"} float="right" ml={{ base:"1%", md:"5%"}}>
            <Stack >
                    <Box bg={'white'} p={10} >
                          <Text
                          fontFamily='Manrope'
                          fontStyle='normal'
                          fontWeight='600'
                          fontSize='16.32px'
                          lineHeight="22px"
                          color="#374151"
                          >Information</Text>
                          <FormControl mb={5}>
                              <FormLabel
                                fontWeight="400"
                                fontSize="13"
                                lineHeight="17px"
                                color="#374151">
                              Last Update</FormLabel>
                              <Input type="datetime" readOnly value={request.updatedAt} ></Input>
                          </FormControl>
                          <FormControl mb={5}>
                              <FormLabel  fontWeight="400"
                                fontSize="13"
                                lineHeight="17px"
                                color="#374151">By</FormLabel>
                              <Input type="text" readOnly value={ request.userId && (request.userId.firstName+" "+request.userId.lastName)} ></Input>
                          </FormControl>
                          {/* <FormControl mb={5}>
                              <FormLabel  fontWeight="500"
                                fontSize="14px"
                                lineHeight="17px"
                  color="#374151">Categories</FormLabel>
                    <RadioGroup defaultValue='1' onChange={(event) => {

                                        setRequest({...request, category: event});
                                    }} >
                      <Stack>
                            <Radio value='2'>None</Radio>
                            <Radio value='3'>User</Radio>
                      </Stack>
                    </RadioGroup>
                      
                           </FormControl> */}
                  <CircularProgress isIndeterminate id={"loader"}  display={'none'}></CircularProgress>
                  <Button
                    type='submit'
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    padding="12.6933px 18.1333px"
                    gap="9.07px"
                    width="100%"
                    color="white"
                    height="45.39px"
                    background="#4FB58B"
                    borderRadius="6px"
                          >Save</Button>
                           {window.location.pathname.split("/")[2] ?
                    <HStack mt={3}>
                      <UnpublishButton onClick={unPublished}></UnpublishButton>
                      <DeleteModal onClick={deleteEntry}></DeleteModal>
                    </HStack> : ""}
                </Box>
              {/* </GridItem> */}
              </Stack>
            </Flex>
            </form>
          </Flex>
          
      </Flex>
    </Flex>
  );
};

export default CreateRequest;
