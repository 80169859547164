import React, { useState } from 'react';
import { 
  Text, Box, useColorModeValue, 
  FormControl,FormLabel, Input,
  Stack, Flex, Hide, HStack,
  Radio, RadioGroup, Button, Select
} from '@chakra-ui/react';
import Sidebar from '../components/sidebarComponent';
import HeaderDashboard from '../components/headerDashboard';
import { Country } from 'country-state-city';
import { Admin } from '../services/admin';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateTeam = () => {
 
  const countries = Country.getAllCountries();  
  const [team, setTeam] = useState({});

  const handleChange = (e) => {
    setTeam({ ...team, [e.target.name]: e.target.value });
  }
  
  const submitForm = (e) => {
    e.preventDefault();
    
    const createUser = new Admin();
    if (window.location.pathname.split('/').length === 3) {
      setTeam({ ...team, "id": window.location.pathname.split('/')[2] })
      createUser.editTeam(team)
        .then((response) => {
          document.querySelector("#loader").style = "display: none";
          response.statusCode !== 200 ? toast.error(response.message) : toast.success(response.message)
        })
    } else {
      createUser.createUser(team).then((response) => {
        response.statusCode !== 200 ? toast.error(response.message) : toast.success(response.message)
      })
    }
  }
   
  

  return (
    <Flex display={'flex'} bg={useColorModeValue('#F5F5F5')} minH={{ base: 'auto', md: "100vh" }} position={'relative'} width={'auto'}>
    <Hide below="md">
        <Sidebar></Sidebar>
    </Hide>
    <ToastContainer></ToastContainer>
      <Flex flexDirection={'column'} w={'100%'} minH={{ base: 'auto', md: "100vh" }}>
         <HeaderDashboard></HeaderDashboard>
        {/* <HeaderDashboard name={auth.user.applicationUser}></HeaderDashboard> */}
        
        <Flex p={5} flexDirection={{ base: "column", md: "row" }}>
        <form onSubmit={submitForm} width="100%" style={{ display: "contents" }}>
          <Flex h="100%" w={{ base: "100%", md: "65%" }}  direction={"column"} mb={5}>
            <Stack>
                            <Box bg={'white'}
              p={10}
              
                              borderRadius={'6px'}
                minH="100vh"
                
                             >
                               <HStack pb={5}>
                                <FormControl fontSize="14px" fontWeight={"500"}>
                                    <FormLabel>First name</FormLabel>
                      <Input name={'firstName'} onChange={handleChange} />
                              </FormControl>
                              <FormControl fontSize="14px" fontWeight={"500"}>
                                    <FormLabel>Last name</FormLabel>
                                    <Input name={'lastName'} onChange={handleChange} />
                                </FormControl>
                          </HStack>
                          <HStack pb={5}>
                                <FormControl fontSize="14px" fontWeight={"500"}>
                                    <FormLabel>Email</FormLabel>
                                    <Input type="email" name={'email'} onChange={handleChange} />
                              </FormControl>
                              <FormControl fontSize="14px" fontWeight={"500"}>
                                    <FormLabel>Country</FormLabel>
                                      <Select type="text" name={"country"} onChange={handleChange}>
                                        <option></option>
                                        {countries && (countries.map((country) => (
                                          <option value={country.name}>
                                            {country.name}
                                          </option>
                                        )))}
                                          </Select>
                      
                    
                                </FormControl>
                          </HStack>
                          <HStack pb={5}>
                                <FormControl fontSize="14px" fontWeight={"500"}>
                                    <FormLabel>Password</FormLabel>
                                    <Input type="password" name={"password"} onChange={handleChange} />
                              </FormControl>
                              <FormControl fontSize="14px" fontWeight={"500"}>
                                    
                                </FormControl>
                          </HStack>
                
        
              </Box>
            </Stack>
            </Flex>
            <Flex h="100%" w={{ base: "100%", md: "30%" }} direction={"column"} float="right" ml={{ base:"1%", md:"5%"}}>
            <Stack >
                    <Box bg={'white'} p={10} >
                          <Text
                          fontFamily='Manrope'
                          fontStyle='normal'
                          fontWeight='600'
                          fontSize='16.32px'
                          lineHeight="22px"
                          color="#374151"
                          >Information</Text>
                          <FormControl mb={5}>
                              <FormLabel
                                fontWeight="400"
                                fontSize="13"
                                lineHeight="17px"
                                color="#374151">
                              Last Update</FormLabel>
                              <Input type="date" readOnly value={'10/10/2020'}></Input>
                            </FormControl>
                              <FormControl mb={5}>
                                            <FormLabel  fontWeight="500"
                                              fontSize="14px"
                                              lineHeight="17px"
                                color="#374151">Categories</FormLabel>
                                  <RadioGroup onChange={(event) => {
                                      setTeam({ ...team, type: event });
                                      }}
                                  >
                                    <Stack>
                                          <Radio value='admin'>Admin</Radio>
                                          <Radio value='superadmin'>Super Admin</Radio>
                                    </Stack>
                                  </RadioGroup>
                                    
                                        </FormControl>
                
                  
                  <Button
                    type={"submit"}
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    padding="12.6933px 18.1333px"
                    gap="9.07px"
                    width="100%"
                    color="white"
                    height="45.39px"
                    background="#4FB58B"
                    borderRadius="6px"
                          >Save</Button>
                       
                </Box>
              {/* </GridItem> */}
              </Stack>
            </Flex>
          </form>
          </Flex>
          
      </Flex>
    </Flex>
  );
};

export default CreateTeam;
